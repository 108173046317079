import { Injectable, inject } from '@angular/core';

import { ApiAction } from '@sulser-print/constants/api-actions';
import { ApiModule } from '@sulser-print/constants/api-modules/api-modules';
import { Project } from '@sulser-print/models/project/project';
import { QuickFilling } from '@sulser-print/models/quick-filling/quick-filling';
import { Response } from '@sulser-print/models/response';
import { tap } from 'rxjs';

import { QuickFillingStore } from './quick-filling.store';
import { RequestService } from './request.service';

@Injectable({ providedIn: 'any' })
export class QuickFillingService extends RequestService {
	private readonly apiUri = ApiModule.SEND_DATA;

	private readonly quickFillingStore = inject(QuickFillingStore, { optional: true });

	add(projectId: Project['id'], items: Array<Omit<QuickFilling, 'id'>>) {
		return this.httpClient.post<Response<Array<QuickFilling>>>(`${this.apiUri}/${ApiAction.ADD}/${projectId}`, items).pipe(
			tap((response) => {
				if (response.data) {
					this.quickFillingStore?.addMultiple(response.data);
				}
			}),
		);
	}

	download(projectId: Project['id']) {
		return this.httpClient.get(`${this.apiUri}/${ApiAction.DOWNLOAD}/${projectId}`, { responseType: 'blob' });
	}

	empty(projectId: Project['id']) {
		return this.httpClient.delete<Response<null>>(`${this.apiUri}/${ApiAction.EMPTY}/${projectId}`).pipe(
			tap(() => {
				this.quickFillingStore?.empty();
			}),
		);
	}

	getSendLink(projectId: number) {
		return this.httpClient.get<Response<string>>(`${this.apiUri}/${ApiAction.SEND_LINK}/${projectId}`);
	}

	print(projectId: Project['id']) {
		return this.httpClient.post(`${this.apiUri}/${ApiAction.PRINT}/${projectId}`, {});
	}

	readFromProject(projectId: Project['id']) {
		return this.httpClient
			.get<Response<Array<QuickFilling>>>(`${this.apiUri}/${ApiAction.READ}/${projectId}`)
			.pipe(tap((response) => this.quickFillingStore?.set(response.data ?? [])));
	}

	remove(quickFillingId: QuickFilling['id']) {
		return this.httpClient.delete<Response<null>>(`${this.apiUri}/${ApiAction.REMOVE}/${quickFillingId}`).pipe(
			tap(() => {
				this.quickFillingStore?.remove(quickFillingId);
			}),
		);
	}

	send(
		projectId: Project['id'],
		data: {
			link: string;
			news: string;
			recipients: Array<string>;
			regarding: string;
		},
	) {
		return this.httpClient.post<Response<null>>(`${this.apiUri}/${ApiAction.SEND}/${projectId}`, data);
	}
}
